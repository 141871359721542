<template>
    <div>
        <el-tag
            :disable-transitions="true"
            type="info"
            size="mini"
            :closable="!disabled"
            @close="handleClose(index)"
            class="wordList"
            v-for='(tag, index) in tags'
            :key='index'
            >
            {{ tag.tag_name }}
        </el-tag>
        <el-autocomplete
            class="inputNewTag"
            v-if="inputVisible"
            v-model="inputTag"
            ref="saveInputTag"
            @select="handleSelect"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="true"
            :disabled="disabled"
        >
            <template v-slot="{ item }">
                <div class="sub-label" v-if="item.parentLabel || item.childLabel">
                    <span v-if="item.parentLabel">{{ item.parentLabel }}</span>
                    <span v-if="item.childLabel"> > {{ item.childLabel }}</span>
                </div>
                <div class="tag">{{ item.label }}</div>
            </template>
        </el-autocomplete>
        <el-button
            v-else
            :disabled="disabled"
            size="mini"
            width="100px"
            class="wordList buttonNewTag"
            @click="showInput"
            >
            + 追加
        </el-button>
    </div>
</template>
<script>
import * as types from '../store/mutation-types'

export default {
    name: 'TagSelect',
    props: {
        article: Object,
        plot: Object,
        tags: Array,
        input: Function,
        close: Function,
        suggestList: Array,
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            inputTag:'',
            inputVisible: false,
        }
    },
    methods: {
        showInput () {
            this.inputVisible = true
            this.$nextTick(() => {
                this.$refs.saveInputTag.$refs.input.focus()
            })
        },
        handleInput() {
            if (this.article) {
                this.input(this.article, this.inputTag)
            } else {
                this.input(this.plot, this.inputTag)
            }
            this.inputVisible = false
            this.inputTag = ''
        },
        handleClose(index) {
            if (this.article) {
                this.close(this.article, index)
            } else {
                this.close(this.plot, index)
            }
        },
        querySearch(queryString, cb) {
            const values = this.suggestList.map(item => ({value: item.id, label: item.label, parentLabel: item.parent, childLabel: item.child}))
            const results = queryString ? values.filter(this.createFilter(queryString)) : values
            cb(results)
        },
        createFilter(queryString) {
            return (item) => {
                return (item.label.toLowerCase().indexOf(queryString.toLowerCase())  > -1)
            }
        },
        handleSelect(item) {
            this.inputTag = item.value
            this.handleInput()
        },
    }
}
</script>
<style scoped>
.inputNewTag {
    margin:5px 5px 0 0;
    vertical-align: bottom;
}
.wordList {
    margin:0 5px 5px 0;
    width: auto;
}
.sub-label {
    overflow-x: auto;
    overflow-y: hidden;
    font-size: 11px;
    color: rgb(132, 146, 166);
    height: 23px;
}
.tag {
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 10px;
}
</style>
