<template>
    <div>
    <el-table class="list" :data="tableData" style="font-size: 12px" max-height="800">
        <el-table-column width="100" :render-header="renderTableHeader(['ID','記事ID'])" >
            <template slot-scope="scope" v-if="scope.row.id">
                {{ scope.row.id }}
                <br>
                <a :href="scope.row.edit_article_url" target="_blank">{{ scope.row.article_id }}</a>
            </template>
        </el-table-column>
        <el-table-column :min-width="110" :render-header="renderTableHeader(['SEOキーワード','親タグ','子タグ','孫タグ'])">
            <template slot-scope="scope">
                <div class="keywordsWrap">
                    <keyword-select :keywords="scope.row.plot.keywords" />
                </div>
                <div class="tagsWrap">
                    <tag-select
                        ref="TagSelect"
                        v-if="parentTagList.length > 0"
                        :article="scope.row.article"
                        :plot="scope.row.plot"
                        :tags="scope.row.article.parent_tags"
                        :input="handleInputParentTag"
                        :close="handleCloseParentTag"
                        :suggestList="parentTagList(scope.row.article)"
                        :disabled="isLoadingTagList"
                    />
                    <el-input v-else-if="scope.row.article.parent_tags.length > 0" value="読込中..." size="mini" :style="{width:'80px', display:'block'}" disabled />
                    <el-input v-if="scope.row.article.parent_tags.length === 0" value="親タグを設定してください" size="mini" :style="{width:'200px', display:'block', margin: '5px 0 5px 0'}" disabled />
                    <tag-select
                        ref="TagSelect"
                        v-else-if="childTagList.length > 0"
                        :article="scope.row.article"
                        :plot="scope.row.plot"
                        :tags="scope.row.article.child_tags"
                        :input="handleInputChildTag"
                        :close="handleCloseChildTag"
                        :suggestList="childTagList(scope.row.article)"
                        :disabled="isLoadingTagList"
                    />
                    <el-input v-else-if="scope.row.article.child_tags.length > 0" value="読込中..." size="mini" :style="{width:'80px', display:'block'}" disabled />
                    <el-input v-if="scope.row.article.child_tags.length === 0" value="子タグを設定してください" size="mini" :style="{width:'200px', display:'block', margin: '5px 0 5px 0'}" disabled />
                    <tag-select
                        ref="TagSelect"
                        v-else-if="grandchildTagList.length > 0"
                        :article="scope.row.article"
                        :plot="scope.row.plot"
                        :tags="scope.row.article.grandchild_tags"
                        :input="handleInputGrandchildTag"
                        :close="handleCloseGrandchildTag"
                        :suggestList="grandchildTagList(scope.row.article)"
                        :disabled="isLoadingTagList"
                    />
                    <el-input v-else-if="scope.row.article.grandchild_tags.length > 0" value="読込中..." size="mini" :style="{width:'80px', display:'block'}" disabled />
                </div>
            </template>
        </el-table-column>
        <el-table-column width="160" :render-header="renderTableHeader(['フォーマット','カテゴリ','記事種別'])" >
            <template slot-scope="scope">
                <el-select v-model="scope.row.article.format"
                           size="mini"
                >
                    <el-option
                        v-for="item in formatFilterList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        :disabled="scope.row.article.format_disabled">
                    </el-option>
                </el-select>
                <el-input
                    type="text"
                    v-model="scope.row.article.video_code"
                    size="mini"
                    placeholder="動画ID"
                    v-if="scope.row.article.format === 'video'">
                </el-input>
                <el-select v-model="scope.row.plot.category_id"
                           size="mini"
                >
                    <el-option
                        v-for="item in categoryList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
                <el-select v-model="scope.row.plot.article_type_id"
                           size="mini"
                >
                    <el-option
                        v-for="item in articleTypeList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column :width="170" :render-header="renderTableHeader(['ライター','担当者','編集'])" >
            <template slot-scope="scope">
                <el-autocomplete
                    v-model="scope.row.article.writer_name"
                    type="text"
                    size="mini"
                    :fetch-suggestions="querySearchWriter"
                    @focus="focusSuggest(scope.$index)"
                    @select="setWriterId"
                    placeholder="ライター"
                ></el-autocomplete>
                <p class="displayItem">
                    {{scope.row.admin_name}}
                </p>
                <el-autocomplete
                    v-model="scope.row.editing_admin_name"
                    type="text"
                    size="mini"
                    :fetch-suggestions="querySearchAdmin"
                    @focus="focusSuggest(scope.$index)"
                    @select="setEditingAdminId"
                    placeholder="編集"
                ></el-autocomplete>
            </template>
        </el-table-column>
        <el-table-column label="プロット" >
            <template slot-scope="scope">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 1, maxRows: 10}"
                    :cols="40"
                    v-model="scope.row.plot.plot">
                </el-input>
            </template>
        </el-table-column>
        <el-table-column :width="150" :render-header="renderTableHeader(['目安文字量','ステータス','希望納期'])" >
            <template slot-scope="scope">
                <el-select v-model="scope.row.plot.base_char_count"
                           size="mini"
                >
                    <el-option
                        v-for="item in baseCharCountList"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
                <br>
                <p class="alignCenter">
                    {{ scope.row.status }}
                </p>
                    <el-date-picker
                        v-model="scope.row.due_at"
                        type="date"
                        style="width:100%"
                        size="mini"
                        placeholder="希望納期">
                    </el-date-picker>
            </template>
        </el-table-column>
        <el-table-column :width="130" label="操作">
            <template slot-scope="scope" >
                <div v-if="scope.row.id">
                    <el-button
                        class="btnEdit unitBtn"
                        size="mini"
                        @click="backToPlot(scope.row)"
                        v-if="scope.row.enable_back_to_plot"
                    >プロットに戻す</el-button>
                    <el-button
                        class="btnEdit unitBtn"
                        size="mini"
                        type="warning"
                        @click="doUpdate(scope.row)"
                    >更新</el-button>
                </div>
                <div v-else>
                    <el-button
                        class="unitBtn"
                        size="mini"
                        type="primary"
                        @click="doCreate(scope.row)">
                        新規作成
                    </el-button>
                </div>
            </template>
        </el-table-column>
    </el-table>
    </div>
</template>

<script>
import TagSelect     from '../TagSelect'
import KeywordSelect from '../KeywordSelect'
import * as types    from '../../store/mutation-types'

export default {
    name: 'DirectionRow',
    components: {
        TagSelect,
        KeywordSelect
    },
    props: {
        tableData: Array,
        categoryList: Array,
        articleTypeList: Array,
        baseCharCountList: Array,
        formatFilterList: Array,
        update: Function,
        create: Function,
        backToPlotConfirm: Function,
        tagList: Array,
    },
    data () {
        return {
            focusedLineIndex: null
        }
    },
    computed:{
        parentTagList() {
            return (article) => {
                return this.tagList.map(tag => ({id: tag.id, label: tag.tag_name}))
            }
        },
        childTagList() {
            return (article) => {
                return this.tagList
                    .filter(parentTag => article.parent_tags.some(selectParentTag => selectParentTag.id === parentTag.id))
                    .flatMap(parentTag => parentTag.children)
                    .map(childTag => {
                        const index = article.parent_tags.findIndex(parentTag => parentTag.id === childTag.parent_tag_id)
                        return {id: childTag.id, label: childTag.tag_name, parent: article.parent_tags[index].tag_name}
                    })
            }
        },
        grandchildTagList() {
            return (article) => {
                return this.tagList
                    .filter(parentTag => article.parent_tags.some(selectParentTag => selectParentTag.id === parentTag.id))
                    .flatMap(parentTag => parentTag.children)
                    .filter(childTag => article.child_tags.some(selectChildTag => selectChildTag.id === childTag.id))
                    .flatMap(child => child.grandchildren)
                    .map(grandchildTag => {
                        const childTagIndex = article.child_tags.findIndex(childTag => childTag.id === grandchildTag.child_tag_id)
                        const parentTagIndex = article.parent_tags.findIndex(parentTag => {
                            const childTag = this.tagList.flatMap(parentTag => parentTag.children).filter(tag => tag.id === article.child_tags[childTagIndex].id)[0]
                            return parentTag.id === childTag.parent_tag_id
                        })
                        return {id: grandchildTag.id, label: grandchildTag.tag_name, parent: article.parent_tags[parentTagIndex].tag_name, child: article.child_tags[childTagIndex].tag_name}
                    })
            }
        },
        isLoadingTagList() {
            return this.tagList.length === 0
        }
    },
    methods: {
        doUpdate (form) {
            this.update(form.id, form)
        },
        doCreate (form) {
            this.create(form)
        },
        backToPlot(form) {
            this.backToPlotConfirm(form)
        },
        async querySearchWriter(queryString, cb) {
            // ライター名空にしてもwriter_idがリセットされないのでここでsetterを呼び出す
            if (queryString === '') {
                let item = {writer_id : ''}
                return this.setWriterId(item)
            }

            if (queryString) {
                const {data,error} = await axios.get('/api/writers/suggest', {
                    params: {
                        suggest_word:queryString,
                        selected_format: this.tableData[this.focusedLineIndex].article.format
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'ライター検索に失敗しました')
                    return
                }
                cb(data.writers)
            }
        },
        async querySearchAdmin(queryString, cb) {
            if (queryString) {
                const {data,error} = await axios.get('/api/admins/suggest/editor', {
                    params: {
                        suggest_word:queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, '管理者検索に失敗しました')
                    return
                }
                cb(data.admins)
            }
        },
        focusSuggest(index) {
            this.focusedLineIndex = index
        },
        setWriterId(item) {
            this.tableData[this.focusedLineIndex]['article']['writer_id'] = item.writer_id
        },
        setEditingAdminId(item) {
            this.tableData[this.focusedLineIndex]['editing_admin_id'] = item.admin_id
        },
        handleInputParentTag(article, value) {
            if (value && article.parent_tags.findIndex(item => item.id === value) === -1) {
                const tag = this.tagList.find(item => item.id === value)
                article.parent_tags.push({id: tag.id, tag_name: tag.tag_name})
            }
        },
        handleInputChildTag(article, value) {
            if (value && article.child_tags.findIndex(item => item.id === value) === -1) {
                const tag = this.tagList.flatMap(parentTag => parentTag.children).find(item => item.id === value)
                article.child_tags.push({id: tag.id, tag_name: tag.tag_name})
            }
        },
        handleInputGrandchildTag(article, value) {
            if (value && article.grandchild_tags.findIndex(item => item.id === value) === -1) {
                const tag = this.tagList.flatMap(parentTag => parentTag.children)
                    .flatMap(child => child.grandchildren)
                    .find(item => item.id === value)
                article.grandchild_tags.push({id: tag.id, tag_name: tag.tag_name})
            }
        },
        handleCloseParentTag(article, index) {
            this.tagList.filter(tag => {
                return (article.parent_tags[index].id === tag.id)
            }).forEach(parentTag => {
                parentTag.children.forEach(childTag => {
                    const index = article.child_tags.findIndex(tag => tag.id === childTag.id)
                    if (index !== -1) {
                        article.child_tags.splice(index, 1)
                    }
                    childTag.grandchildren.forEach(grandchildTag => {
                        const index = article.grandchild_tags.findIndex(tag => tag.id === grandchildTag.id)
                        if (index !== -1) {
                            article.grandchild_tags.splice(index, 1)
                        }
                    })
                })
            })
            article.parent_tags.splice(index, 1)
        },
        handleCloseChildTag(article, index) {
            this.tagList.filter(parentTag => {
                const childTag = parentTag.children.filter(childTag => article.child_tags[index].id === childTag.id)
                return (childTag.length !== 0)
            }).forEach(parentTag => {
                parentTag.children.forEach(childTag => {
                    childTag.grandchildren.forEach(grandchildTag => {
                        const index = article.grandchild_tags.findIndex(tag => tag.id === grandchildTag.id)
                        if (index !== -1) {
                            article.grandchild_tags.splice(index, 1)
                        }
                    })
                })
            })
            article.child_tags.splice(index, 1)
        },
        handleCloseGrandchildTag(article, index) {
            article.grandchild_tags.splice(index, 1)
        },
    }
}
</script>

<style>
    .el-table td, th{
        vertical-align: top !important;
    }
</style>

<style scoped>
    .el-button {
        margin: 5px 0 5px 10px;
    }

    .unitBtn {
        width: 100%;
    }

    .list {
        vertical-align: top;
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .el-select {
        margin: 5px 0 5px 10px;
    }
    .el-input {
        margin: 5px 0 5px 10px;
        width: 130px;
    }

    .alignCenter {
        text-align: center;
    }
    .el-autocomplete {
        margin: 5px 0 5px 10px;
    }
    .displayItem {
        margin: 7px 0 8px 10px;
    }
    .keywordsWrap {
        border-bottom : 2px solid #EBEEF5;
    }
    .tagsWrap {
        padding-top: 5px;
    }
</style>


