<template>
    <el-card class="topCard">
        <el-form @submit.native.prevent label-width="140px" >
            <el-row>
                <el-col :span="8">
                    <el-form-item label="記事種別">
                        <el-select @change="update" v-model="form.article_type_id" size="mini">
                            <el-option
                                v-for="item in articleTypeList"
                                :key="item.id"
                                :label="item.type_name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="カテゴリ" style="margin-bottom: 10px;">
                        <el-select @change="changeCategory" :value="form.category_id" v-model="form.category_id" size="mini">
                            <el-option
                                v-for="item in categoryList"
                                :key="item.id"
                                :label="item.category_name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="監修者" style="margin-bottom: 10px;" v-if="isTr">
                        <el-select @change="update" v-model="form.supervisor_writer_id" size="mini" clearable filterable placeholder="選択しない">
                            <el-option
                                v-for="item in supervisorList"
                                :key="item.id"
                                :label="item.writer_name"
                                :value="item.id">
                                <span style="float: left">{{ item.writer_name }}</span>
                                <span style="float: right; color: #8492a6; font-size: 12px;">{{ item.screen_name }}</span>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8">
                    <el-form-item label="公開ライター" style="margin-bottom: 10px;" v-if="isTr">
                        <el-autocomplete
                            v-model="publishWriterName"
                            type="text"
                            size="mini"
                            :fetch-suggestions="searchPublishWriter"
                            placeholder="公開ライター"
                            @select="setPublishWriterId"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="リライトライター" style="margin-bottom: 10px;" v-if="isTr && isRewrite">
                        {{ rewrite.writer.writer_name }}
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="元記事公開ライター" style="margin-bottom: 10px;" v-if="isTr && isRewrite">
                        {{ rewrite.origin_article.publish_writer.writer_name }}
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="SEOキーワード">
                        <el-tag
                            v-for="keyword in seoKeywords"
                            type="info"
                            size="mini"
                            class="seoKeywordItem"
                            :key="keyword.id"
                        >{{ keyword }}</el-tag>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item>
                        <el-button type="primary" size="mini" :disabled="isLoadingTagList" :loading="isLoadingTagSuggest" @click="fetchRecommendTags">
                            タグサジェスト
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item>
                        <el-button
                            v-for="tag in recommendTags"
                            :key="tag.tag_path_id"
                            :type="tag.is_best_match ? 'success' : ''"
                            plain
                            size="mini" style="margin: 0 10px 10px 0;"
                            @click="addRecommendTag(tag)"
                        >{{ tag.full_label }}</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="親タグ">
                        <tag-list :disabled="isLoadingTagList" :tags="parentTags" :input="handleInputParentTag" :close="handleCloseParentTag" :suggestList='suggestParentTagList' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="子タグ">
                        <tag-list :disabled="isLoadingTagList" :tags="childTags" :input="handleInputChildTag" :close="handleCloseChildTag" :suggestList='suggestChildTagList' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="孫タグ" >
                        <tag-list :disabled="isLoadingTagList" :tags="grandchildTags" :input="handleInputGrandchildTag" :close="handleCloseGrandchildTag" :suggestList='suggestGrandchildTagList' />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8" v-if="isTr">
                    <el-form-item label="記事管理" >
                        <el-checkbox v-model="form.is_trend" @change="update">トレンド記事</el-checkbox>
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="isTr">
                    <el-checkbox v-model="form.is_mate" @change="update">Mate記事</el-checkbox>
                </el-col>
                <el-col :span="8" v-if="isTr">
                    <el-checkbox v-model="form.is_original" @change="update">オリジナル記事</el-checkbox>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="8" v-if="article.format === 'normal'">
                    <el-form-item label="レシピ区分" style="margin-bottom: 10px;">
                        <el-select @change="update" :value="form.recipe_type" v-model="form.recipe_type" size="mini">
                            <el-option value=""></el-option>
                            <el-option
                                v-for="item in articleRecipeTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</template>

<script>
import * as types from '../../../store/mutation-types'
import TagList from './ArticleBaseTable/TagList'

const formDefaultData = {
    article_type_id: null,
    supervisor_writer_id: null,
    category_id: null,
    is_trend: false,
    is_original: false,
    is_mate: false,
    parent_tags: [],
    child_tags: [],
    grandchild_tags: [],
    recipe_type: null,
    publish_writer_id: null,
}
export default {
    name:'ArticleBaseTable',
    props: {
        article: Object,
        seoKeywords: Array,
        categoryList: Array,
        articleTypeList: Array,
        supervisorList: Array,
        tagList: Array,
        fetch: Function,
        isTr: Boolean,
        articleRecipeTypeList: Array,
        rewriteData: Array,
    },
    components: {
        TagList
    },
    data() {
        return {
            parentTags: [],
            childTags: [],
            grandchildTags: [],
            is_mate: false,
            is_original: false,
            is_trend: false,
            publishWriterName: null,
            recommendTags: [],
            isLoadingTagSuggest: false,
        }
    },
    mounted() {
        this.parentTags = this.form.parent_tags.map(tag => ({id: tag.id, label: tag.tag_name}))
        this.childTags = this.form.child_tags.map(tag => ({id: tag.id, label: tag.tag_name}))
        this.grandchildTags = this.form.grandchild_tags.map(tag => ({id: tag.id, label: tag.tag_name}))
        this.publishWriterName = this.article.publish_writer.writer_name
    },
    computed:{
        form() {
            return Object.assign({}, formDefaultData, _.pick(this.article, Object.keys(formDefaultData)))
        },
        suggestParentTagList() {
            return this.tagList.map(tag => ({id: tag.id, label: tag.tag_name}))
        },
        suggestChildTagList() {
            return this.tagList
                .filter(parentTag => this.parentTags.some(selectParentTag => selectParentTag.id === parentTag.id))
                .flatMap(parentTag => parentTag.children)
                .map(childTag => {
                    const index = this.parentTags.findIndex(parentTag => parentTag.id === childTag.parent_tag_id)
                    return {id: childTag.id, label: childTag.tag_name, parent: this.parentTags[index].label}
                })
        },
        suggestGrandchildTagList() {
            return this.tagList
                .filter(parentTag => this.parentTags.some(selectParentTag => selectParentTag.id === parentTag.id))
                .flatMap(parentTag => parentTag.children)
                .filter(childTag => this.childTags.some(selectChildTag => selectChildTag.id === childTag.id))
                .flatMap(child => child.grandchildren)
                .map(grandchildTag => {
                    const childTagIndex = this.childTags.findIndex(childTag => childTag.id === grandchildTag.child_tag_id)
                    const parentTagIndex = this.parentTags.findIndex(parentTag => {
                        const childTag = this.tagList.flatMap(parentTag => parentTag.children).filter(tag => tag.id === this.childTags[childTagIndex].id)[0]
                        return parentTag.id === childTag.parent_tag_id
                    })
                    return {id: grandchildTag.id, label: grandchildTag.tag_name, parent: this.parentTags[parentTagIndex].label, child: this.childTags[childTagIndex].label}
                })
        },
        isRewrite() {
            return this.rewriteData.length > 0
        },
        rewrite() {
            return this.rewriteData[0]
        },
        isLoadingTagList() {
            return this.tagList.length === 0
        }
    },
    methods: {
        async update() {
            this.$store.commit(types.EDITOR_LOADING, true)
            this.form.tags = {parents: this.parentTags, children: this.childTags, grandchildren: this.grandchildTags}
            const {error} = await axios.patch('/api/articles/' + this.article.id + '/base', {
                article_type_id: this.form.article_type_id,
                supervisor_writer_id: this.form.supervisor_writer_id,
                category_id: this.form.category_id,
                is_trend: this.form.is_trend,
                is_mate: this.form.is_mate,
                is_original: this.form.is_original,
                tags: this.form.tags,
                recipe_type: this.form.recipe_type,
                publish_writer_id: this.form.publish_writer_id,
            })
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, error)
            } else {
                await this.fetch()
            }
            this.$store.commit(types.EDITOR_LOADING,false)
        },
        async searchPublishWriter(queryString, cb) {
            if (queryString) {
                const { data, error } = await axios.get('/api/writers/suggest', {
                    params: {
                        suggest_word: queryString
                    }
                })
                if (error) {
                    this.$store.commit(types.SHOW_ERROR_MESSAGE, 'ライター検索に失敗しました')
                    return
                }
                cb(data.writers)
            }
        },
        handleInputParentTag(value) {
            if (this.setParentTags(value)) {
                this.update()
            }
        },
        handleInputChildTag(value) {
            if (this.setChildTags(value)) {
                this.update()
            }
        },
        handleInputGrandchildTag(value) {
            if (this.setGrandchildTag(value)) {
                this.update()
            }
        },
        setParentTags(value) {
            if (value && this.parentTags.findIndex(item => item.id === value) === -1) {
                const tag = this.tagList.find(item => item.id === value)
                this.parentTags.push({id: tag.id, label: tag.tag_name})
                return true
            }
            return false
        },
        setChildTags(value) {
            if (value && this.childTags.findIndex(item => item.id === value) === -1) {
                const tag = this.tagList.flatMap(parentTag => parentTag.children).find(item => item.id === value)
                this.childTags.push({id: tag.id, label: tag.tag_name})
                return true
            }
            return false
        },
        setGrandchildTag(value) {
            if (value && this.grandchildTags.findIndex(item => item.id === value) === -1) {
                const tag = this.tagList.flatMap(parentTag => parentTag.children)
                    .flatMap(child => child.grandchildren)
                    .find(item => item.id === value)
                this.grandchildTags.push({id: tag.id, label: tag.tag_name})
                return true
            }
            return false
        },
        handleCloseParentTag(index) {
            this.tagList.filter(tag => {
                return (this.parentTags[index].id === tag.id)
            }).forEach(parentTag => {
                parentTag.children.forEach(childTag => {
                    const index = this.childTags.findIndex(tag => tag.id === childTag.id)
                    if (index !== -1) {
                        this.childTags.splice(index, 1)
                    }
                    childTag.grandchildren.forEach(grandchildTag => {
                        const index = this.grandchildTags.findIndex(tag => tag.id === grandchildTag.id)
                        if (index !== -1) {
                            this.grandchildTags.splice(index, 1)
                        }
                    })
                })
            })
            this.parentTags.splice(index, 1)
            this.update()
        },
        handleCloseChildTag(index) {
            this.tagList.filter(parentTag => {
                const childTag = parentTag.children.filter(childTag => this.childTags[index].id === childTag.id)
                return (childTag.length !== 0)
            }).forEach(parentTag => {
                parentTag.children.forEach(childTag => {
                    childTag.grandchildren.forEach(grandchildTag => {
                        const index = this.grandchildTags.findIndex(tag => tag.id === grandchildTag.id)
                        if (index !== -1) {
                            this.grandchildTags.splice(index, 1)
                        }
                    })
                })
            })
            this.childTags.splice(index, 1)
            this.update()
        },
        handleCloseGrandchildTag(index) {
            this.grandchildTags.splice(index, 1)
            this.update()
        },
        changeCategory() {
            this.update()
        },
        async setPublishWriterId(writer) {
            this.$set(this.form, 'publish_writer_id', writer.writer_id)
            await this.update()
        },
        async fetchRecommendTags () {
            this.isLoadingTagSuggest = true
            const { data, error } = await axios.get('/api/tags/article_suggest', {
                params: {
                    article_id: this.article.id
                }
            })
            this.isLoadingTagSuggest = false
            if (error) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'タグのサジェストデータ取得に失敗しました。')
                return
            }
            if (data.length === 0) {
                this.$store.commit(types.SHOW_ERROR_MESSAGE, 'サジェストできるタグがありませんでした。')
                return
            }

            this.recommendTags = data
        },
        addRecommendTag (tag) {
            let isSet = false
            if (tag.parent_tag_id) {
                isSet = this.setParentTags(tag.parent_tag_id) ? true : isSet
            } else if (tag.child_tag_id) {
                isSet = this.setChildTags(tag.child_tag_id) ? true : isSet
            } else if (tag.grandchild_tag_id) {
                isSet = this.setGrandchildTag(tag.grandchild_tag_id) ? true : isSet
            }
            // relations内には上位階層のタグが入るので、子の場合は親のみ、孫の場合は親・子が入る。
            if (tag.relations.length) {
                tag.relations.forEach(relation => {
                    if (relation.parent_tag_id) {
                        isSet = this.setParentTags(relation.parent_tag_id) ? true : isSet
                    }
                    if (relation.child_tag_id) {
                        isSet = this.setChildTags(relation.child_tag_id) ? true : isSet
                    }
                })
            }
            if (isSet) {
                this.update()
            }
        }
    },
}
</script>

<style scoped>
.el-card.is-always-shadow.topCard {
    box-shadow: none;
    margin-top: 30px;
    margin-bottom: 15px;
}
.seoKeywordItem {
    margin: 4px 5px 0 0;
}
</style>
